import ReactDOM from "react-dom";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const { useState, useEffect, useRef } = React;

const NavButton = ({ children, onClick }) => (
  <button
    onClick={onClick}
    className="text-white hover:text-primary transition-colors duration-300 ml-6 text-sm uppercase tracking-wider"
  >
    {children}
  </button>
);

const TypewriterText = ({ text }) => {
  const [displayText, setDisplayText] = useState("");
  const index = useRef(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDisplayText((prevText) => {
        if (index.current < text.length) {
          index.current += 1;
          return text.slice(0, index.current);
        } else {
          clearInterval(intervalId);
          return prevText;
        }
      });
    }, 25);

    return () => clearInterval(intervalId);
  }, [text]);

  return <span className="font-mono">{displayText}</span>;
};

const FeatureCard = ({ icon, title, description }) => (
  <div className="border border-primary p-6 rounded-lg transition-colors duration-300 animate-pulse">
    <div className="text-4xl mb-4">{icon}</div>
    <h3 className="text-xl font-semibold mb-2 text-primary">{title}</h3>
    <p className="text-white">{description}</p>
  </div>
);

const Review = ({ name, text }) => (
  <div className="border border-primary p-6 rounded-lg mb-6">
    <p className="text-white mb-4">"{text}"</p>
    <p className="text-primary font-semibold">- {name}</p>
  </div>
);

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-black text-white font-mono p-6 pt-24">
      {/* Added 'pt-24' to provide top padding */}
      <div className="container mx-auto">
        <h1 className="text-4xl font-bold mb-6 text-primary">Privacy Policy</h1>
        <p className="text-lg mb-4">
          PuffTrack, if you choose to use the social features, collects your
          name, email, and timestamps of your puffs during the last 30 days.
          This is necessary for the function of the social features. This
          feature is completely optional.
        </p>
        <p className="text-lg mb-4">
          The data collected is stored on our servers and is only used for
          logging you into your account and sharing your puff data with friends.
        </p>
        <p className="text-lg mb-4">
          If you wish to delete your account, you may request this through the
          app, and your account and associated data will be permanently deleted.
          We do not use your data for any other purposes.
        </p>
        <p className="text-lg">
          Your privacy is important to us, and we are committed to ensuring that
          your data is handled responsibly and securely.
        </p>
      </div>
    </div>
  );
};

const App = () => {
  const [puffCount, setPuffCount] = useState(256);
  const [isCountdownOver, setIsCountdownOver] = useState(false);
  const [isFlashing, setIsFlashing] = useState(false);
  const featuresRef = useRef(null);
  const aboutRef = useRef(null);
  const howItWorksRef = useRef(null);
  const reviewsRef = useRef(null);
  const downloadRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setPuffCount((prevCount) => {
        if (prevCount > 0) {
          return prevCount - 1;
        } else {
          clearInterval(interval);
          setIsCountdownOver(true);
          return 0;
        }
      });
    }, 50);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (isCountdownOver) {
      setIsFlashing(true);
      const flashInterval = setInterval(() => {
        setIsFlashing((prev) => !prev);
      }, 100);

      setTimeout(() => {
        clearInterval(flashInterval);
        setIsFlashing(false);
      }, 1000);
    }
  }, [isCountdownOver]);

  const scrollTo = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Router>
      <div className="min-h-screen flex flex-col bg-black text-white font-mono">
        <header className="p-6 flex justify-between items-center fixed w-full bg-black bg-opacity-90 z-10 border-b border-primary">
          <div className="flex items-center">
            <h1
              className={`font-display text-4xl font-bold mr-4 ${
                isFlashing ? "text-white" : "text-primary"
              }`}
            >
              PuffTrack
            </h1>
            <div className="flex flex-col items-start">
              <span className="text-3xl font-light">{puffCount}</span>
              <span className="text-xs text-primary">PUFFS REMAINING</span>
            </div>
          </div>
          <nav className="hidden md:block">
            <NavButton onClick={() => scrollTo(aboutRef)}>About</NavButton>
            <NavButton onClick={() => scrollTo(featuresRef)}>
              Features
            </NavButton>
            <NavButton onClick={() => scrollTo(howItWorksRef)}>
              How It Works
            </NavButton>
            <NavButton onClick={() => scrollTo(reviewsRef)}>Reviews</NavButton>
            <NavButton onClick={() => scrollTo(downloadRef)}>
              Download
            </NavButton>
          </nav>
        </header>

        <Routes>
          <Route
            path="/"
            element={
              <>
                <main className="flex-grow pt-24">
                  {/* Hero Section */}
                  <section className="min-h-screen flex items-center justify-center p-6">
                    <div className="text-center">
                      <h2 className="text-5xl font-light mb-6">
                        <TypewriterText text="Track Your Vaping" />
                      </h2>
                      <p className="text-white text-xl mb-12 max-w-2xl mx-auto">
                        <TypewriterText text="Monitor your habits. Set goals. Take control." />
                      </p>
                      <button
                        onClick={() => scrollTo(downloadRef)}
                        className="bg-primary text-black py-3 px-8 rounded-full text-lg font-semibold hover:bg-red-600 transition-colors duration-300 animate-pulse"
                      >
                        Download PuffTrack Now
                      </button>
                    </div>
                  </section>

                  {/* About Section */}
                  <section ref={aboutRef} className="py-20">
                    <div className="container mx-auto px-6">
                      <div className="border border-primary p-8 rounded-lg">
                        <h2 className="text-4xl font-light mb-12 text-center text-primary">
                          About PuffTrack
                        </h2>
                        <div className="flex flex-col md:flex-row items-center justify-between">
                          <div className="md:w-1/2 mb-8 md:mb-0">
                            <p className="text-white text-lg mb-4">
                              PuffTrack is your personal vaping companion,
                              designed to help you understand and manage your
                              vaping habits. Our app provides a simple yet
                              powerful way to track your puffs, set reduction
                              goals, and visualize your progress over time.
                            </p>
                            <p className="text-white text-lg">
                              Whether you're looking to cut back, quit entirely,
                              or simply gain insights into your vaping behavior,
                              PuffTrack offers the tools and support you need to
                              make informed decisions about your health and
                              lifestyle.
                            </p>
                          </div>
                          <div className="md:w-1/4">
                            <img
                              src="app-screenshot1.png"
                              alt="PuffTrack App Screenshot"
                              className="w-full h-auto rounded-lg border border-primary"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  {/* Features Section */}
                  <section ref={featuresRef} className="py-20">
                    <div className="container mx-auto px-6">
                      <h2 className="text-4xl font-light mb-12 text-center text-primary">
                        Key Features
                      </h2>
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        <FeatureCard
                          icon="👥"
                          title="Social Progress Tracking"
                          description="Connect with friends, share achievements, and motivate each other on your vaping reduction journey."
                        />
                        <FeatureCard
                          icon="👆"
                          title="One-Tap Tracking"
                          description="Effortlessly log your puffs with a simple tap, making it easy to maintain an accurate record of your vaping habits."
                        />
                        <FeatureCard
                          icon="⏳"
                          title="Vape Lifespan Prediction"
                          description="Get accurate estimates of how long your current vape will last based on your usage patterns."
                        />
                        <FeatureCard
                          icon="💰"
                          title="Financial Insights"
                          description="Visualize your potential monthly savings from reduced vaping, helping you set and achieve financial goals."
                        />
                        <FeatureCard
                          icon="📊"
                          title="30-Day Data Visualization"
                          description="Access detailed graphs of your vaping patterns over the past month for comprehensive insights."
                        />
                        <FeatureCard
                          icon="🎯"
                          title="Personalized Goal Setting"
                          description="Set custom reduction targets and track your progress towards a healthier lifestyle."
                        />
                      </div>
                    </div>
                  </section>

                  {/* How It Works Section */}
                  <section ref={howItWorksRef} className="py-20">
                    <div className="container mx-auto px-6">
                      <h2 className="text-4xl font-light mb-12 text-center text-primary">
                        How It Works
                      </h2>
                      <div className="space-y-8">
                        <div>
                          <h3 className="text-2xl font-semibold mb-4 text-primary">
                            1. Track Your Puffs
                          </h3>
                          <p className="text-white mb-4">
                            Every time you take a puff, simply tap the button in
                            the app. PuffTrack will log the time and date,
                            building a comprehensive record of your vaping
                            habits. This simple action is the foundation of your
                            journey.
                          </p>
                        </div>
                        <div>
                          <h3 className="text-2xl font-semibold mb-4 text-primary">
                            2. Set Your Goals
                          </h3>
                          <p className="text-white mb-4">
                            Based on your tracking data, PuffTrack helps you set
                            realistic reduction goals. Whether you want to cut
                            back by 10% or quit entirely, the app provides
                            personalized recommendations and helps you create an
                            achievable plan.
                          </p>
                        </div>
                        <div>
                          <h3 className="text-2xl font-semibold mb-4 text-primary">
                            3. Visualize Your Progress
                          </h3>
                          <p className="text-white mb-4">
                            Watch your progress unfold through intuitive graphs
                            and charts. PuffTrack's 30-day visualization feature
                            allows you to see patterns in your vaping behavior,
                            identify triggers, and celebrate your successes
                            along the way.
                          </p>
                        </div>
                        <div>
                          <h3 className="text-2xl font-semibold mb-4 text-primary">
                            4. Connect and Motivate
                          </h3>
                          <p className="text-white mb-4">
                            Join the PuffTrack community by connecting with
                            friends. Share your progress, compete in friendly
                            challenges, and support each other throughout your
                            journeys. The power of social motivation can be a
                            game-changer in achieving your goals.
                          </p>
                        </div>
                        <div>
                          <h3 className="text-2xl font-semibold mb-4 text-primary">
                            5. Gain Financial Insights
                          </h3>
                          <p className="text-white mb-4">
                            As you reduce your vaping, PuffTrack calculates your
                            potential savings. Watch as small changes add up to
                            significant financial benefits over time, providing
                            extra motivation to stick to your goals.
                          </p>
                        </div>
                      </div>
                    </div>
                  </section>

                  {/* Reviews Section */}
                  <section ref={reviewsRef} className="py-20">
                    <div className="container mx-auto px-6">
                      <h2 className="text-4xl font-light mb-12 text-center text-primary">
                        User Reviews
                      </h2>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        <Review
                          name="Alex M."
                          text="PuffTrack has been a game-changer for me. The visual representation of my vaping habits really opened my eyes. I've cut down by 50% in just a month!"
                        />
                        <Review
                          name="Sarah K."
                          text="I love the social feature! Competing with friends to reduce our puff counts has made the process fun and motivating. Highly recommend!"
                        />
                        <Review
                          name="Mike T."
                          text="The financial insights are incredible. I had no idea how much I was spending on vaping until PuffTrack showed me. It's been a huge motivator to quit."
                        />
                        <Review
                          name="Emily R."
                          text="As a data nerd, I appreciate the detailed graphs and stats. PuffTrack makes it easy to understand my habits and make informed decisions about my health."
                        />
                      </div>
                    </div>
                  </section>

                  {/* Download Section */}
                  <section ref={downloadRef} className="py-20">
                    <div className="container mx-auto px-6 text-center">
                      <h2 className="text-4xl font-light mb-12 text-primary">
                        Ready to Take Control?
                      </h2>
                      <p className="text-white text-xl mb-8 max-w-2xl mx-auto">
                        Start your journey towards healthier habits today.
                        PuffTrack is almost here to help you take charge of your
                        vaping behavior.
                      </p>
                      <div className="text-primary text-4xl font-bold animate-pulse">
                        COMING SOON ON APP STORE
                      </div>
                    </div>
                  </section>
                </main>
              </>
            }
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>

        <footer className="py-10 border-t border-primary">
          <div className="container mx-auto px-6 text-center text-white">
            <a
              href="/privacy-policy"
              className="text-primary hover:underline mt-4 block"
            >
              Privacy Policy
            </a>
            <p>&copy; 2024 Yalın Kaan Şenol. All rights reserved.</p>
          </div>
        </footer>
      </div>
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
